<template>
    <vx-card :title="(this.id ? 'Edit' : 'Create') + ' Inter Warehouse'">
        <div class="vx-row mb-6">
            <vs-button
                class="ml-4 mt-2"
                color="danger"
                icon-pack="feather"
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <br>
                <div class="vx-row mb-6" v-if="readonly && id">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Inter Warehouse STO Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>STO Type</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <StoType
                            :value="this.selectedType"
                            @selected="(val) => { this.selectedType = val; onChangeType(val); }"
                        ></StoType>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Source Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Warehouse
                            :value="this.selectedSrcWarehouse"
                            @selected="(val) => { this.selectedSrcWarehouse = val; onChangeSrcWarehouse(val); }">
                        </Warehouse>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Dest. Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <DestWarehouse
                            :value="this.selectedDestWarehouse"
                            :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : 0"
                            @selected="(val) => { this.selectedDestWarehouse = val; onChangeDestWarehouse(val); }"
                        ></DestWarehouse>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Imposition</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!-- IMPOSITION -->
                        <vs-input
                            v-if="this.selectedType != 'Alokasi'"
                            type="text"
                            v-model="this.selectedImposition"
                            :readonly="true"
                            class="w-full"
                        />
                        <v-select
                            v-if="this.selectedType == 'Alokasi'"
                            :filterable="false"
                            :options="this.impositionOption"
                            :value="this.selectedImposition"
                            label="label" @input="(val) => { onChangeImposition(val); }"
                            :disabled="this.disabled"
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Req. Delivery Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            type="date"
                            class="w-full"
                            v-model="reqDeliveryDate"
                            name="reqDeliveryDate"
                            :min="now"
                            @input="(val) => { onChangeReqDelDate(val); }"
                        ></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Est. Time Arrived</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input type="date" class="w-full" v-model="eta" name="eta" :min="reqDeliveryDate"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Notes</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="notes" width="100%" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Estimate Shipment Cost</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input type="number" v-model="shipmentCost" class="w-full" @input="(val) => { onChangeShipmentCost(val); }"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Cost Ratio (%)</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input type="number" :readonly="true" v-model="costRatio" class="w-full"></vs-input>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Attachments</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex items-center">
                            <label>Attachment<small style="color: red">(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small></label>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file" multiple="multiple"
                                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls" @change="onChangeAttachment"
                            />
                        </div>
                        <vs-divider style="margin-left: 0%">
                            List Attachment
                        </vs-divider>
                    </div>
                    <div class="vx-row mb-3 mt-6" style="margin-left: 0%">
                        <table class="vs-table vs-table--tbody-table">
                            <template>
                                <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                    <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                    <td>
                                        <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                    </td>
                                    <td class="td vs-table--td">
                                        <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-download" 
                                                :disabled="!tr.PathFile"
                                                @click.stop="download(tr)"
                                            />
                                        </vx-tooltip>
                                    </td>
                                    <td class="td vs-table--td">
                                        <template>
                                            <vx-tooltip text="Delete">
                                                <vs-button 
                                                    type="line" 
                                                    icon-pack="feather" 
                                                    icon="icon-trash"
                                                    @click.stop="handleDeleteAttachment(i)"
                                                />
                                            </vx-tooltip>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <vue-easy-lightbox
                        :visible="visible"
                        :imgs="images"
                        :index="index"
                        @hide="handleHide"
                    />
                </div>
            </div>
        </div>
        <br>
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>Relocation Items</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
                <thead class="vs-table--thead">
                    <tr>
                        <th style="width: 35%;">SKU</th>
                        <th style="width: 20%;">Batch / Exp. Date</th>
                        <th style="width: 10%;">Available Qty (UOM)</th>
                        <th style="width: 7%;">UOM</th>
                        <th style="width: 10%;">Moved Qty</th>
                        <th style="width: 11%;">HU</th>
                        <th style="width: 7%;">Amount UOM</th>
                        <th style="width: 5%;">Add Row</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="skuLine.option_sku"
                                v-model="skuLine.selected_sku"
                                :selected-label="''"
                                :select-label="''"
                                :deselect-label="''"
                                :label="'label'"
                                :track-by="'label'"
                                :placeholder="'Select SKU'"
                                @input="(val) => { onChangeSku(val, index); }"
                                @search-change="(val) => { handleSearchLines(index, val, 'sku') }"
                            />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="skuLine.option_batch"
                                v-model="skuLine.selected_batch"
                                :selected-label="''"
                                :select-label="''"
                                :deselect-label="''"
                                :label="'label'"
                                :track-by="'label'"
                                :placeholder="'Batch'"
                                @input="(val) => { onChangeBatch(val, index); }"
                                @search-change="(val) => { handleSearchLines(index, val, 'batch') }"
                            />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input
                                type="text"
                                v-model="skuLine.available_qty"
                                :disabled="true"
                                class="w-full"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input
                                type="text"
                                v-model="skuLine.uom"
                                :disabled="true"
                                class="w-full"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input
                                type="number"
                                v-model="skuLine.qty"
                                class="w-full"
                                @input="(val) => { onChangeQty(val, index); }"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="skuLine.option_hu"
                                v-model="skuLine.selected_hu"
                                :selected-label="''"
                                :select-label="''"
                                :deselect-label="''"
                                :placeholder="'Unit'"
                                @input="(val) => { onChangeHu(val, index); }"
                            />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input
                                type="number"
                                v-model="skuLine.amount_uom"
                                :disabled="true"
                                class="w-full"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td" style="text-align: center">
                            <div class="vx-input-group flex">
                                <vs-button
                                    v-if="skuLines.length > 1"
                                    @click.stop="removeRow(index)"
                                    size="small"
                                    color="danger"
                                    icon-pack="feather"
                                    icon="icon-trash"
                                    style="margin-right: 5px"
                                    title="Remove Row"
                                />
                                <vs-button
                                    v-if="index == skuLines.length - 1"
                                    @click.stop="addRow()"
                                    size="small"
                                    color="success"
                                    icon-pack="feather"
                                    icon="icon-plus"
                                    title="Add Row"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit" v-if="this.id ? $store.getters['user/hasPermissions']('edit') : $store.getters['user/hasPermissions']('create')">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import StoType from "./component/StoType.vue";
import Warehouse from "./component/Warehouse.vue";
import DestWarehouse from "./component/DestWarehouse.vue";
import Date from "./component/Date.vue";
import moment from "moment";
import Swal from 'sweetalert2'
import vSelect from "vue-select";
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        StoType,
        Warehouse,
        DestWarehouse,
        Date,
        vSelect,
        VueEasyLightbox
    },
    data() {
        return {
            id: null,
            readonly: true,
            disabled: true,
            code: null,
            selectedType: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            impositionOption: [],
            selectedImposition: null,
            selectedImposition2: null,
            reqDeliveryDate: moment().format("YYYY-MM-DD"),
            minDeliveryDate: moment().add(1, "days").format("YYYY-MM-DD"),
            eta: moment().add(1, "days").format("YYYY-MM-DD"),
            now: moment().add(0, "days").format("YYYY-MM-DD"),
            notes: null,
            shipmentCost: 0,
            costRatio: 0,
            skuLines: [
                {
                    stock_relocation_plan_line_id: 0,
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                    batch_id: 0,
                    option_batch: [],
                    selected_batch: null,
                    available_qty: null,
                    uom: null,
                    amount_uom: 0,
                }
            ],
            draw: 0,
            firstDraw: 0,
            timeOuts: null,

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData();
        } else {
            this.selectedType = 'By Request';
        }
        this.$store.dispatch("user/getPermissions", "simple-sto");
    },
    methods: {
        handleSpliceLines() {
            this.skuLines.splice(1)
            this.skuLines.forEach(val => {
                val.stock_relocation_plan_line_id = 0
                val.option_sku = []
                val.selected_sku = null
                val.qty = 0
                val.option_hu = []
                val.selected_hu = null
                val.option_batch = []
                val.selected_batch = null
                val.batch_id = 0
                val.available_qty = null
                val.uom = null
                val.amount_uom = 0
            })
        },
        handleGetItemOptions() {
            this.$http.get("/api/wms/v1/outbound/simple-sto/get-warehouse-items", {
                params: {
                    length: 10,
                    warehouse_id: this.selectedSrcWarehouse.id,
                    search: "",
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        resp.data.records.map(function (x) {
                            return (x.label = x.sku_code + " - " + x.name);
                        });
                        this.skuLines.forEach(val => {
                            val.option_sku = resp.data.records
                        })
                    } else {
                        this.skuLines.forEach(val => {
                            val.option_sku = []
                        })
                    }
                } else {
                    this.skuLines.forEach(val => {
                        val.option_sku = []
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        handleGetUnitOptions(index, sku_code) {
            this.$http.get("/api/wms/v1/outbound/simple-sto/get-unit-options", {
                params: {
                    sku_code: sku_code,
                    search: "",
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    if (resp.data.length) {
                        this.skuLines[index].option_hu = resp.data
                    } else {
                        this.skuLines[index].option_hu = []
                    }
                } else {
                    this.skuLines[index].option_hu = []
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetBatchOptions(index, itemID, unit) {
            this.$http.get(this.$store.state.outbound.simpleSto.baseUrlPath + "/get-batch", {
                params: {
                    warehouse_id: this.selectedSrcWarehouse.id,
                    item_id: itemID,
                    unit: unit,
                    search: "",
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.option_batch) {
                        resp.data.option_batch.map(function (x) {
                            return (x.label = x.batch_external + " / " + x.exp_date);
                        });
                        this.skuLines[index].option_batch = resp.data.option_batch;
                    } else {
                        this.skuLines[index].option_batch = [];
                    }
                } else {
                    this.skuLines[index].option_batch = [];
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        handleGetAvailableQtyUnit(index, skuCode, warehouseID, batchExternal) {
            this.$http.get("/api/wms/v1/outbound/simple-sto/get-available-qty-unit", {
                params: {
                    sku_code: skuCode,
                    warehouse_id: warehouseID,
                    batch: batchExternal,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.skuLines[index].available_qty = this.handleUpdateFormat(resp.data.available_qty)
                    this.skuLines[index].option_hu = resp.data.option_units
                    this.skuLines[index].uom = resp.data.uom
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetAmountUom(index, skuCode, unit) {
            this.$http.get("/api/wms/v1/outbound/simple-sto/get-amount-uom", {
                params: {
                    sku_code: skuCode,
                    unit: unit,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.skuLines[index].amount_uom = resp.data
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleUpdateFormat(val) {
            let cleanValue = val.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        handleSearchLines(index, val, type) {
            if (this.timeOuts) {
                clearTimeout(this.timeOuts)
            }

            if (type == "sku") {
                this.timeOuts = setTimeout(this.newSearchSku(val, index), 400)
            } else if (type == "batch") {
                this.timeOuts = setTimeout(this.newSearchBatch(val, index), 400)
            }
        },
        handleIfStart() {
            let start = true
            this.skuLines.forEach(val => {
                if (!val.selected_sku) {
                    start = false
                }

                if (val.qty < 1) {
                    start = false
                }

                if (!val.selected_hu) {
                    start = false
                }
            })

            return start
        },
        getData() {
            this.$vs.loading();
            this.$http.get(this.$store.state.outbound.simpleSto.baseUrlPath + "/get-edit/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.code = resp.data.code
                    this.selectedType = resp.data.sto_type
                    this.selectedSrcWarehouse = resp.data.selected_src_warehouse
                    this.selectedDestWarehouse = resp.data.selected_dest_warehouse
                    this.selectedImposition = resp.data.imposition.label
                    this.selectedImposition2 = resp.data.imposition
                    this.reqDeliveryDate = resp.data.req_delivery_date
                    this.eta = resp.data.eta
                    this.notes = resp.data.notes
                    this.shipmentCost = resp.data.shipment_cost
                    this.costRatio = resp.data.cost_ratio
                    this.skuLines = resp.data.sku_lines
                    if (resp.data.attachment) {
                        resp.data.attachment.forEach((val) => {
                            this.fileAttachment.push({
                                id: val.id,
                                NameFile: val.file_name,
                                PathFile: val.file_url,
                            });
                            this.images.push(val.file_url);
                        });
                    }
                    for (let i = 0; i < this.skuLines.length; i++) {
                        this.handleGetBatchOptions(i, this.skuLines[i].selected_sku.id, "")
                        this.handleGetAvailableQtyUnit(i, this.skuLines[i].selected_sku.sku_code, this.selectedSrcWarehouse.id, this.skuLines[i].selected_batch.batch_external)
                    }
                    this.handleGetItemOptions()
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    console.log(resp)
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error)
            });
        },
        setDefault() {
            this.id = null
            this.code = null
            this.selectedSrcWarehouse = null
            this.selectedDestWarehouse = null
            this.reqDeliveryDate = null
            this.eta = null
            this.notes = null
            this.skuLines = [
                {
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                    option_batch: [],
                    selected_batch: null,
                    available_qty: null,
                    uom: null,
                    amount_uom: 0,
                }
            ]
            this.file = null
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.outbound.simpleSto.baseRouterName,
            });
        },
        handleSubmit() {
            if (!this.selectedType) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "STO Type is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedSrcWarehouse) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Source Warehouse is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedDestWarehouse) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Dest. Warehouse is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.selectedSrcWarehouse.id == this.selectedDestWarehouse.id) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Source and destination warehouase is required to different",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            const selectedDate = moment(this.reqDeliveryDate, "YYYY-MM-DD");
            const currentDate = moment();
            if (selectedDate.isBefore(currentDate, 'day')) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Request Delivery Date minimal is today",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.shipmentCost) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Shipment Cost is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.skuLines.length) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Est. Time Arrived is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let message = ""
            let skus = []
            this.skuLines.forEach((val) => {
                if (!val.selected_sku) {
                    message = "please check SKU, SKU is required"
                    return
                }

                if (!val.selected_batch) {
                    message = "please select Batch for SKU "+val.selected_sku.sku_code
                    return
                }

                if (val.selected_hu == null || val.selected_hu == "") {
                    message = "please check Unit for SKU "+val.selected_sku.sku_code
                    return
                }

                if (val.qty < 1) {
                    message = "quantity is required more than zero for SKU "+val.selected_sku.sku_code
                    return
                }

                skus.push({
                    stock_relocation_plan_line_id: val.stock_relocation_plan_line_id ? val.stock_relocation_plan_line_id : 0,
                    item_id: val.selected_sku.id,
                    item_unit: val.selected_hu,
                    quantity: parseInt(val.qty),
                    batch: val.selected_batch.batch_external
                });
            });

            if (message != "") {
                Swal.fire({
                    title: 'Please check SKU',
                    text: message,
                    icon: 'info',
                });
                return false;
            }

            let formData = new FormData();
            formData.append("sto_type", this.selectedType)
            formData.append("from_warehouse_id", this.selectedSrcWarehouse.id)
            formData.append("to_warehouse_id", this.selectedDestWarehouse.id)

            if (this.selectedType == 'Normalisasi Stock') {
                formData.append("cost_warehouse_id", this.selectedSrcWarehouse.id)
            } else if (this.selectedType == 'By Request') {
                formData.append("cost_warehouse_id", this.selectedDestWarehouse.id)
            } else if (this.selectedType == 'Alokasi') {
                formData.append("cost_warehouse_id", this.selectedImposition2.id)
            }

            formData.append("req_delivery_date", this.reqDeliveryDate
                ? moment(this.reqDeliveryDate).format("YYYY-MM-DD")
                : null)
            formData.append("eta", this.eta
                ? moment(this.eta).format("YYYY-MM-DD")
                : null)
            formData.append("notes", (this.notes)
                ? this.notes
                : "")
            formData.append("shipment_cost", this.shipmentCost)
            formData.append("cost_ratio", this.costRatio)
            formData.append("skus", JSON.stringify(skus))

            if (this.fileAttachment.length > 0) {
                for (let index = 0; index < this.fileAttachment.length; index++) {
                    formData.append("attachments[]", this.fileAttachment[index].File ? this.fileAttachment[index].File : this.fileAttachment[index].id);
                }
            }

            Swal.fire({
                title: 'Submit this document',
                text: "Are you sure want to " + (this.id ? "update" : "create") + " this document?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit'
            })
            .then(result => {
                if (result.isConfirmed) {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/outbound/simple-sto/create-or-update/" + (this.id ? this.id : 0), formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            Swal.fire({
                                title: 'Done!',
                                text: 'Plan Document has been ' + (this.id ? 'updated' : 'created'),
                                icon: 'success'
                            })
                            this.draw++;
                            this.setDefault();
                            this.handleBack();
                        } else {
                            this.$vs.loading.close();
                            Swal.fire({
                                title: 'Something went wrong',
                                text: resp.message,
                                icon: 'info',
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'Something went wrong',
                            text: error,
                            icon: 'info',
                        });
                        console.log(error)
                    });
                }
            })
        },
        newSearchSku(search, index) {
            this.$http.get("/api/wms/v1/outbound/simple-sto/get-warehouse-items", {
                params: {
                    length: 10,
                    warehouse_id: this.selectedSrcWarehouse.id,
                    search: search,
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        resp.data.records.map(function (x) {
                            return (x.label = x.sku_code + " - " + x.name);
                        });
                        this.skuLines[index].option_sku = resp.data.records
                    } else {
                        this.skuLines[index].option_sku = [];
                    }
                } else {
                    this.skuLines[index].option_sku = [];
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        onChangeSku(val, index) {
            this.skuLines[index].selected_sku = val
            this.skuLines[index].selected_hu = null
            this.skuLines[index].qty = 0
            this.skuLines[index].option_batch = []
            this.skuLines[index].selected_batch = null
            this.skuLines[index].available_qty = null
            this.skuLines[index].uom = null
            this.skuLines[index].amount_uom = 0
            this.handleGetBatchOptions(index, val.id, "")
        },
        onChangeHu(val, index) {
            this.skuLines[index].selected_hu = val
            this.handleGetAmountUom(index, this.skuLines[index].selected_sku.sku_code, val)
            if (this.handleIfStart()) {
                this.drawData(this)
            }
        },
        newSearchBatch(search, index) {
            this.$http.get(this.$store.state.outbound.simpleSto.baseUrlPath + "/get-batch", {
                params: {
                    warehouse_id: this.selectedSrcWarehouse.id,
                    item_id: this.skuLines[index].selected_sku.id,
                    unit: this.skuLines[index].selected_hu,
                    search: search,
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.option_batch) {
                        resp.data.option_batch.map(function (x) {
                            return (x.label = x.batch_external + " / " + x.exp_date);
                        });
                        this.skuLines[index].option_batch = resp.data.option_batch;
                    }
                } else {
                    this.skuLines[index].option_batch = [];
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        onChangeBatch(val, index) {
            this.skuLines[index].selected_batch = val
            this.handleGetAvailableQtyUnit(index, this.skuLines[index].selected_sku.sku_code, this.selectedSrcWarehouse.id, val.batch_external)
        },
        onChangeQty(val, index) {
            this.skuLines[index].qty = val
            if (this.skuLines[index].qty < 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Qty must be greater than zero",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.skuLines[index].qty = 0
            }

            if (this.handleIfStart()) {
                this.drawData(this)
            }
        },
        removeRow(index) {
            this.skuLines.splice(index, 1);
            if (this.handleIfStart()) {
                this.drawData(this)
            }
        },
        addRow() {
            this.skuLines.push({
                stock_relocation_plan_line_id: 0,
                option_sku: [],
                selected_sku: null,
                option_hu: [],
                selected_hu: null,
                qty: 0,
                option_batch: [],
                batch_id: 0,
                selected_batch: null,
                amount_uom: 0,
            });
            this.handleGetItemOptions()
        },
        onChangeType(val) {
            if (val === 'Normalisasi Stock') {
                this.selectedImposition = (this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.label : null
            } else if (val === 'By Request') {
                this.selectedImposition = (this.selectedDestWarehouse) ? this.selectedDestWarehouse.label : null
            } else if (val === 'Alokasi') {
                if (this.id && this.firstDraw == 0) {
                    // display cost warehouse
                    this.impositionOption[0] = (this.selectedSrcWarehouse) ? this.selectedSrcWarehouse : null
                    this.impositionOption[1] = (this.selectedDestWarehouse) ? this.selectedDestWarehouse : null

                    if (this.impositionOption[0] === null || this.impositionOption[1] === null) {
                        this.disabled = true
                    } else if (this.impositionOption[0] !== null && this.impositionOption[1] !== null) {
                        this.disabled = false
                    }
                } else if (this.id && this.firstDraw != 0 || !this.id) {
                    this.selectedImposition = null
                    this.impositionOption[0] = (this.selectedSrcWarehouse) ? this.selectedSrcWarehouse : null
                    this.impositionOption[1] = (this.selectedDestWarehouse) ? this.selectedDestWarehouse : null

                    if (this.impositionOption[0] === null || this.impositionOption[1] === null) {
                        this.disabled = true
                    } else if (this.impositionOption[0] !== null && this.impositionOption[1] !== null) {
                        this.disabled = false
                    }
                }
            }
        },
        onChangeSrcWarehouse(val) {
            if (val) {
                this.selectedSrcWarehouse = val
                if (this.selectedType === 'Alokasi') {
                    if (this.id && this.firstDraw == 0) {
                        if (this.selectedDestWarehouse != null) {
                            this.impositionOption = []
                            this.impositionOption.push(this.selectedSrcWarehouse)
                            this.impositionOption.push(this.selectedDestWarehouse)
                            this.disabled = false
                        }
                    } else if (this.id && this.firstDraw != 0 || !this.id) {
                        if (this.selectedDestWarehouse != null) {
                            this.impositionOption = []
                            this.impositionOption.push(this.selectedSrcWarehouse)
                            this.impositionOption.push(this.selectedDestWarehouse)
                            this.selectedImposition = null
                            this.disabled = false
                            this.handleSpliceLines()
                        } else {
                            this.impositionOption = []
                            this.selectedImposition = null
                            this.disabled = true
                        }
                    }
                } else if (this.selectedType === 'Normalisasi Stock') {
                    if (this.id && this.firstDraw == 0) {
                        this.selectedImposition = val.label
                    } else if (this.id && this.firstDraw != 0 || !this.id) {
                        this.selectedImposition = val.label
                        this.handleSpliceLines()
                    }
                } else {
                    if (this.id && this.firstDraw != 0 || !this.id) {
                        this.handleSpliceLines()
                    }
                }

                this.handleGetItemOptions()
            }
        },
        onChangeDestWarehouse(val) {
            if (val) {
                this.selectedDestWarehouse = val
                if (this.selectedType === 'Alokasi') {
                    if (this.id && this.firstDraw == 0) {
                        if (this.selectedSrcWarehouse != null) {
                            this.impositionOption = []
                            this.impositionOption.push(this.selectedSrcWarehouse)
                            this.impositionOption.push(this.selectedDestWarehouse)
                            this.disabled = false
                        }
                        this.firstDraw = 1
                    } else if (this.id && this.firstDraw != 0 || !this.id) {
                        if (this.selectedSrcWarehouse != null) {
                            this.impositionOption = []
                            this.impositionOption.push(this.selectedSrcWarehouse)
                            this.impositionOption.push(this.selectedDestWarehouse)
                            this.selectedImposition = null
                            this.disabled = false
                        } else {
                            this.impositionOption = []
                            this.selectedImposition = null
                            this.disabled = true
                        }
                    }
                } else if (this.selectedType === 'By Request') {
                    if (this.id && this.firstDraw == 0) {
                        this.selectedImposition = val.label
                        this.firstDraw = 2
                    } else if (this.id && this.firstDraw != 0 || !this.id) {
                        this.selectedImposition = val.label
                    }
                } else {
                    if (this.id && this.firstDraw == 0) {
                        this.firstDraw = 3
                    } else if (this.id && this.firstDraw != 0 || !this.id) {
                        // do nothing
                    }
                }
            } else {
                if (this.selectedType === 'By Request') {
                    this.selectedImposition = null
                } else if (this.selectedType === 'Alokasi') {
                    this.impositionOption = []
                    this.impositionOption.push(this.selectedSrcWarehouse)
                }
            }
        },
        onChangeImposition(val) {
            this.selectedImposition = val.label
            this.selectedImposition2 = val
        },
        onChangeReqDelDate(val) {
            this.minDeliveryDate = moment(val).add(1, "days").format("YYYY-MM-DD");
            this.eta = moment(val).add(1, "days").format("YYYY-MM-DD");
        },
        drawData: _.debounce((t) => {
            if (t.shipmentCost != 0) {
                let skus = []
                t.skuLines.forEach((val) => {
                    if (val.selected_hu == null || val.selected_hu == "") {
                        return
                    }

                    if (val.qty < 1) {
                        return
                    }

                    skus.push({
                        item_id: val.selected_sku.id,
                        item_unit: val.selected_hu,
                        quantity: parseInt(val.qty),
                    })
                })

                const formData = new FormData();
                formData.append("from_warehouse_id", t.selectedSrcWarehouse.id)
                formData.append("shipment_cost", t.shipmentCost)
                formData.append("skus", JSON.stringify(skus))

                t.$vs.notify({
                    color: "warning",
                    title: "Calculating..",
                    text: "Calculating cost ratio in progress",
                    position: "top-center",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });

                t.$http.post(t.$store.state.outbound.simpleSto.baseUrlPath + "/calculate", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        t.costRatio = resp.data.cost_ratio
                    } else {
                        t.costRatio = 0
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            } else {
                // do nothing
            }
        }, 1000),
        onChangeShipmentCost(val) {
            this.shipmentCost = val
            if (this.handleIfStart()) {
                this.calculateCost(this.shipmentCost, this)
            }
        },
        calculateCost: _.debounce((cost, t) => {
            t.$vs.notify({
                color: "warning",
                title: "Calculating..",
                text: "Calculating cost ratio in progress",
                position: "top-center",
                iconPack: "feather",
                icon: "icon-x-circle",
            });

            let skus = []
            t.skuLines.forEach((val) => {
                skus.push({
                    item_id: val.selected_sku.id,
                    item_unit: val.selected_hu,
                    quantity: parseInt(val.qty),
                })
            })

            const formData = new FormData();
            formData.append("from_warehouse_id", t.selectedSrcWarehouse.id)
            formData.append("shipment_cost", cost)
            formData.append("skus", JSON.stringify(skus))

            t.$http.post(t.$store.state.outbound.simpleSto.baseUrlPath + "/calculate", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    t.costRatio = resp.data.cost_ratio
                } else {
                    t.costRatio = 0
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }, 1000),

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        handleDeleteAttachment(index) {
            this.fileAttachment.splice(index, 1);
            this.images.splice(index, 1);
        },
    },
    watch: {
        "selected_src_warehouse": {
            handler(val) {
                this.skuLines.forEach((skuLine) => {
                    skuLine.option_sku = []
                    skuLine.selected_sku = null
                    skuLine.option_hu = []
                    skuLine.selected_hu = []
                    skuLine.qty = 0
                    skuLine.option_batch = []
                    skuLine.selected_batch = null
                    skuLine.available_qty = null
                    skuLine.uom = null
                })
            },
            deep: true
        },
    },
    computed: {
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
}
</script>

<style>
.multiselect--above .multiselect__content-wrapper {
    bottom: auto !important;
    top: 100% !important;
}
</style>